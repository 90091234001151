@import '../../../scss/utilities';

.header {
  color: $ue-black;
  font-family: $ue-header-font;
  font-weight: 700;
  text-transform: capitalize;
  font-size: $ue-font-size-4;
  @each $category, $color in $category-colors {
    &--#{$category} {
      color: $color;
    }
  }
}

.header--link {
  &:focus,
  &:hover {
    text-decoration: none;
    .header {
      color: $ue-black;
    }
  }
}
